import commaNumber from "comma-number";
import { GeneralConfig } from "../../services/StrapiApi";

function abbreviateNumber(number) {
  const symbols = ["", "k", "M", "G", "T", "P", "E"];
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = symbols[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

const RecordItem = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="service-item relative rounded-2.5xl pt-12 transition-shadow hover:shadow-xl">
      <div>
        <span className="inline-block font-display text-4xl font-semibold text-accent">
          {value}
        </span>
      </div>
      <span className="text-lg text-jacarta-700 dark:text-white">{title}</span>
    </div>
  );
};

const convertNumberStr = (value) => {
  if (value >= 100000) {
    return abbreviateNumber(value);
  } else {
    return commaNumber(value);
  }
};

const Record = ({ generalConfig }: { generalConfig: GeneralConfig }) => {
  const { totalCreators, totalFollowers, totalViews } = generalConfig;
  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-2 py-8 sm:grid-cols-2 justify-items-center">
          <RecordItem
            title="Total Followers"
            value={commaNumber(totalFollowers)}
          />
          <RecordItem title="Total Views" value={totalViews} />
        </div>
      </div>
    </div>
  );
};

export default Record;
