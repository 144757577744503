import Link from "next/link";

const Hero_13 = ({ descriptions, header, imagePath }) => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative pb-32 pt-20 lg:pt-48">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <img src="/images/gradient.jpg" alt="gradient" />
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <img src="/images/gradient_dark.jpg" alt="gradient dark" />
        </picture>
        <img
          src="/images/patterns/pattern_donut.png"
          alt="pattern donut"
          className="absolute right-0 top-0 -z-10"
        />

        <div className="ml-auto mr-auto h-full max-w-[91rem] px-4">
          <div className="grid h-full items-center gap-4 lg:grid-cols-12">
            <div className="col-span-5 flex h-full flex-col items-center justify-center py-10 lg:items-start lg:py-20">
              <h1 className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
                {header}
              </h1>
              <p className="mb-8 max-w-md text-center text-lg dark:text-jacarta-200 lg:text-left">
                {descriptions}
              </p>
              <div className="flex space-x-4">
                <Link
                  href="/contact"
                  className="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Contact Us
                </Link>
                <Link
                  href="/creator/"
                  className="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                >
                  Our Creators
                </Link>
              </div>
            </div>

            {/* <!-- Hero image --> */}
            <div className="col-span-6">
              <div className="relative text-center lg:pl-32 lg:text-right">
                <img
                  src={imagePath}
                  alt="crypto consultant hero"
                  className="inline-block rounded-2.5xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_13;
