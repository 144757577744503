import Meta from "../components/Meta";
import { Partners3 } from "../components/component";
import Hero_13 from "../components/hero/hero_13";
import Record from "../components/record/Record";
import Services from "../components/services/services1";
import StrapiApi, {
  CreatorPage,
  GeneralConfig,
  HomePage,
  MediaPage,
} from "../services/StrapiApi";

const Home = ({
  homePage,
  generalConfig,
  homePageTitle,
}: {
  homePage: HomePage;
  generalConfig: GeneralConfig;
  homePageTitle: string;
}) => {
  const {
    descriptions,
    header,
    headline_image: { url: imageUrl },
  } = homePage;
  return (
    <>
      <Meta
        title="Home"
        faviconUrl={homePage.favicon.url}
        companyName={generalConfig.companyName}
      />
      <Hero_13
        descriptions={descriptions}
        header={header}
        imagePath={imageUrl}
      />
      <Record generalConfig={generalConfig} />
      <Partners3 generalConfig={generalConfig} />
      <Services homePage={homePage} />
    </>
  );
};

export async function getStaticProps() {
  const mainApi = StrapiApi.getInstance();

  const generalConfig: GeneralConfig = await mainApi.getGeneralConfig();
  const creatorPage: CreatorPage = await mainApi.getCreator();
  const homePage: HomePage = await mainApi.getHome();
  const { homePage: homePageTitle } = await mainApi.getPageTitle();
  const {
    data: { attributes: mediaPage },
  }: { data: { attributes: MediaPage } } = await mainApi.getMedia();

  return {
    props: {
      homePage,
      generalConfig,
      creatorPage,
      homePageTitle,
    }, // will be passed to the page component as props
  };
}

export default Home;
